<template>
  <v-container>
    <h2 class="HeadingDarkH2-28Center my-3">{{ $t("login.travelling") }}</h2>
    <v-layout dense>
      <v-flex xs12 text-center class="px-5">
        <div>
          <img
            :src="require('@/assets/img/private.svg')"
            style="height: calc(50vh - 158px) !important"
            width="auto"
          />
        </div>
        <div>
          <v-btn
            @click="(profile.travel = false), update()"
            height="35"
            min-width="140"
            depressed
            color="primary"
            class="gradient mt-2 Heading-White-H5-16-Center"
            rounded
            >{{ $t("profile.travel.private") }}</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
    <v-layout dense class="my-5">
      <v-flex xs12
        ><h2 class="HeadingDarkH2-28Center text-uppercase">
          {{ $t("profile.or") }}
        </h2></v-flex
      >
    </v-layout>
    <v-layout dense>
      <v-flex xs12 text-center class="px-5">
        <div>
          <img
            :src="require('@/assets/img/business.svg')"
            style="height: calc(50vh - 158px) !important"
            width="auto"
          />
        </div>
        <div>
          <v-btn
            @click="(profile.travel = true), update()"
            height="35"
            min-width="140"
            depressed
            color="primary"
            class="gradient mt-2 Heading-White-H5-16-Center"
            rounded
            >{{ $t("profile.travel.business") }}</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 class="px-5">
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          hide-overlay
          fullscreen
        >
          <v-card>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                @click="(dialog = false), $router.safePush({ name: 'home' })"
                right
                icon
                x-large
                style="color: var(--v-onbackground-base)"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-toolbar>
            <v-img
              :src="require('@/assets/img/notifications.svg')"
              height="calc(calc(100vh - 400px) - env(safe-area-inset-top))"
              width="calc(calc(100vh - 400px) - env(safe-area-inset-top))"
              class="mx-auto"
            ></v-img>
            <v-container>
              <v-card-title class="justify-center pt-4 HeadingDarkH3-22Center">
                {{ $t("login.dontMissOut") }}
              </v-card-title>
              <v-card-text class="mt-4 px-2 Text-Dark-Text-1---18-Center">
                {{ $t("login.notifications") }}
              </v-card-text>
            </v-container>
            <v-container class="text-center">
              <v-row>
                <v-col cols="12">
                  <!-- TODO Execute Function to enable Notifications -->
                  <v-btn
                    class="gradient Heading-White-H4-18-Center"
                    min-height="48px"
                    min-width="120px"
                    depressed
                    color="primary"
                    @click="notifyMe()"
                    >{{ $t("login.notifyMe") }}</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-btn
                    text
                    class="Text-Dark-Text-1---18-Center"
                    min-height="40px"
                    min-width="120px"
                    @click="
                      (dialog = false), $router.safePush({ name: 'home' })
                    "
                    >{{ $t("login.notNow") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    travel: false,
  }),
  computed: {
    profile() {
      return this.$store.state.profile.data;
    },
  },
  methods: {
    update() {
      this.$store.dispatch("profile/updateProfile", {
        profile: this.profile,
      });
      this.dialog = true;
    },
    notifyMe() {
      this.dialog = false;
      window.localStorage?.setItem("notifications", true);
      this.$store.commit("settings/SET_NOTIFICATIONS", true);
      this.$router.safePush({ name: "home" });
    },
  },
};
</script>
